<template>
<div>
  面板
</div>
</template>

<script>
export default {
  name: "Dashboard",
  data: function(){
    return {
      nick: '',

    }
  },
  methods: {

  },
  mounted() {
    this.nick = localStorage.getItem('nick');
  }
}
</script>

<style scoped>

</style>